// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-budovy-sk-js": () => import("./../../../src/pages/budovy.sk.js" /* webpackChunkName: "component---src-pages-budovy-sk-js" */),
  "component---src-pages-buildings-en-js": () => import("./../../../src/pages/buildings.en.js" /* webpackChunkName: "component---src-pages-buildings-en-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-sk-js": () => import("./../../../src/pages/index.sk.js" /* webpackChunkName: "component---src-pages-index-sk-js" */),
  "component---src-pages-kontakt-sk-js": () => import("./../../../src/pages/kontakt.sk.js" /* webpackChunkName: "component---src-pages-kontakt-sk-js" */),
  "component---src-pages-location-en-js": () => import("./../../../src/pages/location.en.js" /* webpackChunkName: "component---src-pages-location-en-js" */),
  "component---src-pages-lokalita-sk-js": () => import("./../../../src/pages/lokalita.sk.js" /* webpackChunkName: "component---src-pages-lokalita-sk-js" */)
}

